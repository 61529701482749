import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_USER,
  USER_LOADED,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  LOGOUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
} from "../constants";

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  userUuid: localStorage.getItem("userUuid"),
  isAuthenticated: localStorage.getItem("isAuthenticated"),
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("access", payload.access_token);
      // localStorage.setItem("refresh", payload.refresh_token);
      localStorage.setItem("userUuid", payload.uuid);
      localStorage.setItem("isAuthenticated", true);
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access_token,
        refresh: payload.refresh_token,
        userUuid: payload.uuid,
        loading: false,
        isLoggedIn: true,
      };

    case USER_LOADED:
      return {
        ...state,
        user: payload,
        loading: false,
        isLoggedIn: true,
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        refresh: payload.refresh_token,
      };
    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        refresh_token_error: payload,
        isAuthenticated: payload === 400 ? false : true,
      };
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
        loading: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        user: null,
      };

    // eslint-disable-next-line no-fallthrough
    case LOGOUT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("userUuid");
      return {
        ...state,
        isAuthenticated: false,
        access: null,
        refresh: null,
        user: null,
        loading: false,
      };
    default:
      return state;
  }
}
